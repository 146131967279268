<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Oscar Andrés Ochoa Villegas                                   ###### -->
<!-- ###### @date: Enero 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-layout>
      <v-card width="100%">
        <v-card-title>PERFIL DE USUARIO</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content >
              <v-list-item-subtitle>Preferencias y seguridad del usuario</v-list-item-subtitle>
              <div><br/>
                <p class="customVerticalSapce">Opciones no disponibles en el momento</p>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
  </v-layout>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
export default {
  name: "profile",
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
  .customVerticalSapce {
    line-height: 1.3;
  }
</style>